import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({breakpoints, spacing}) => ({
  wrapper: {
    display: 'flex',
    [breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column',
    },
  },
  cards: {
    flex: 1,
    marginLeft: spacing(4),
    [breakpoints.down('sm')]: {
      order: 2,
      margin: 0,
    },
  },
  form: {
    justifyContent: 'normal',
    flex: 1,
    marginRight: spacing(4),
    [breakpoints.down('sm')]: {
      order: 1,
      width: '100%',
      margin: 0,
    },
  },
}))

export default useStyles
